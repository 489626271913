@keyframes portal-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes ripple {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    50% {
        transform: scale(.5);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes outerPulse {
    0% {
        box-shadow: 0 0 3px 5px #6021d8cf inset;
    }

    100% {
        box-shadow: 0 0 8px 50px #fff inset;
    }
}

.portal-frame {
    overflow: visible;
    --portal-color: #483d8b;
    /* Darker purple for a more realistic look */
    width: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    transform: scaleX(0.7) rotateY(60deg);
    filter: contrast(1.75) brightness(1.2);
    box-shadow: 0 0 30px 15px #3d3188, 0 0 60px 30px #302448, 0 0 171px 45px #395375;
    animation: outerPulse 2s infinite alternate linear;
}

.portal-frame::before {
    content: "";
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 8px #fff solid;
    background-clip: border-box;
    filter: blur(30px);
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.portal,
.portal::before {
    position: absolute;
    inset: 0;
    animation: portal-spin 8s infinite linear;
}

.portal-inner,
.portal-ripple {
    position: absolute;
    inset: 10%;
    border-radius: 50%;
}

.portal-inner {
    background: hsl(243 100% 100% / 1);
    filter: blur(5px);
    animation: ripple 2s infinite ease-in-out;
}

.portal-ripple {
    background: radial-gradient(circle, transparent, transparent 40%, rgba(255, 255, 255, 0.2) 70%, red 25%);
    filter: blur(5px);
    animation: ripple 2s infinite ease-in-out;
}

.portal {

    /* Replace with your portal image URL */
    --portal-mask: var(--portal-img) center / cover no-repeat;
    -webkit-mask: var(--portal-mask);
    mask: var(--portal-mask);
}

.portal::before {
    content: "";
    animation-direction: forward;
    background: linear-gradient(to bottom, #483d8b 25%, transparent 50%, #9370db 120%), var(--portal-color);
    filter: blur(130px);
    animation: outerPulse 20s infinite alternate ease-in-out;
}

.portal::after {
    content: "";
    background-image: linear-gradient(20deg, #483d8b, #ba55d3 43%, white);
    position: absolute;
    z-index: -1;
    top: -1%;
    left: -2%;
    animation: portal-spin 2.5s linear infinite;
    filter: blur(20px);
    width: 120%;
    height: 120%;
}

.portal-glow {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(280deg, #483d8b, #5562d385 43%, #9c0efcc7);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    filter: blur(200px);
    border-radius: 50%;
}