@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import 'variables';

.project-detail-title {
    text-align: center;
    margin-top: 0px;
    // Additional styles
  }

.project-detail-outer-container{
  @media (min-width: $breakpoint-xs) {
    padding-top: 60px;
    padding-bottom: 100px;
    background:linear-gradient(0deg,#6C69BF 0%,#8d8cc5 70%,#fff 100%);
  }
  @media (min-width: $breakpoint-sm) {
    padding-top: 70px;
    padding-bottom: 160px;
    background:linear-gradient(0deg,#6C69BF,#fff);
  }
  @media (min-width: $breakpoint-md) {
    padding-top: 80px;
    padding-bottom: 210px;
  }
}

.project-detail-carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  pointer-events: all; 
  cursor: pointer;
  background-color: #6C69BF; // Initial background color
  border-radius: 50%; // Fully rounded corners for a circle
  padding: 5px; // Adjust padding to fit the size of the arrow
  transition: background-color 0.3s ease; // Transition for the background color change

  // Ensure the width and height are equal for a perfect circle
  

  // Center the content with flexbox
  display: flex;
  justify-content: center;
  align-items: center;

  color:#fff;

  @media (min-width: $breakpoint-xs) {
    width: 35px; // Adjust as needed
    height: 35px; // Adjust as needed 
    font-size: 25px;
  }
  @media (min-width: $breakpoint-sm) {
    width: 40px; // Adjust as needed
    height: 40px; // Adjust as needed 
    font-size: 30px;
  }
  @media (min-width: $breakpoint-md) {
    width: 50px; // Adjust as needed
    height: 50px; // Adjust as needed 
    font-size: 30px;
  }
  &:hover {
    background-color: #302f58; /* replace with the actual color code */
  }
}

.arrow-svg {
  fill: #fff; /* This will color your SVG polygon white */
  width: 100%; /* Adjust if necessary to scale the SVG */
  height: 100%; /* Adjust if necessary to scale the SVG */
  transition: all 0.3s ease, fill 0.3s ease; /* Smooth transition for scale and fill */
}


.project-detail-carousel-arrow-left {
  right: 10px; // Adjust the right position as needed
}

.project-detail-carousel-arrow-right {
  left: 10px; // Adjust the left position as needed
}

.project-detail-title{
  color: #6C69BF;
  text-align: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  @media (min-width: $breakpoint-xs) {
    font-size: 30px;
  }
  @media (min-width: $breakpoint-sm) {
    font-size: 38px;
  }
  @media (min-width: $breakpoint-md) {
    font-size: 45px;
  }
}

.project-details-carousel {
  overflow: hidden; // Ensures the border-radius clips the content
  margin-bottom: 1rem; // Add space below the carousel if needed
  @media (min-width: $breakpoint-xs) {
    border-radius: 25px;
  }
  @media (min-width: $breakpoint-sm) {
    border-radius: 45px;
  }
  @media (min-width: $breakpoint-md) {
    border-radius: 45px;
  }
  
  iframe,video,img{
    @media (min-width: $breakpoint-xs) {
      height: 250px !important;
    }
    @media (min-width: $breakpoint-sm) {
      height: 500px !important;
    }
    @media (min-width: $breakpoint-md) {
      height: 500px !important;
    }
  }

  img{
    object-fit: contain !important;
  }

  .MuiIndicator {
    display: none !important; // This hides the slide indicators
  }

  // Apply border-radius to all direct children (images, videos)
  & > * {
    
    @media (min-width: $breakpoint-xs) {
      border-radius: 25px;
    }
    @media (min-width: $breakpoint-sm) {
      border-radius: 45px;
    }
    @media (min-width: $breakpoint-md) {
      border-radius: 45px;
    }
  }
  

  // If there are navigation buttons or other elements that should not have border-radius,
  // you can reset their border-radius to 0
  .some-carousel-nav-class {
    border-radius: 0;
  }

  // Similarly, for iframe or video tags within the carousel
  iframe,
  video,img {
    @media (min-width: $breakpoint-xs) {
      border-radius: 25px;
    }
    @media (min-width: $breakpoint-sm) {
      border-radius: 45px;
    }
    @media (min-width: $breakpoint-md) {
      border-radius: 45px;
    }
  }
}

.project-details-description-container{
  .project-details-description-title{
    color: #FFF;
    text-align: justify;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    
    @media (min-width: $breakpoint-xs) {
      font-size: 24px;
      margin-bottom: 10px;
      margin-top: 15px;
    }
    @media (min-width: $breakpoint-sm) {
      font-size: 26px;
      margin-bottom: 10px;
      margin-top: 15px;
    }
    @media (min-width: $breakpoint-md) {
      font-size: 26px;
      margin-bottom: 10px;
      margin-top: 30px;
    }
  }
  .project-details-description-text{
    color: #FFF;
    text-align: justify;
    font-family: Inter;
    
    font-style: normal;
    font-weight: 400;
    
    @media (min-width: $breakpoint-xs) {
      font-size: 16px;
      line-height: 1.4;
    }
    @media (min-width: $breakpoint-sm) {
      font-size: 18px;
      line-height: 1.3;
    }
    @media (min-width: $breakpoint-md) {
      font-size: 18px;
      line-height: 1.5;
    }
  }

}

.project-details-info{
  .project-details-left{
    
    @media (min-width: $breakpoint-xs) {
      width:100%;
      padding-right: 0px;
      margin-bottom: 20px;
    }
    @media (min-width: $breakpoint-sm) {
      width:80%;
      padding-right: 40px;
      margin-bottom: 0px;
    }
    @media (min-width: $breakpoint-md) {
      width:85%;
      padding-right: 90px;
    }
  }

  .project-details-right{
    
    display: flex;
    justify-items: flex-end;
    flex-wrap: wrap;
    align-content: flex-end;
    @media (min-width: $breakpoint-xs) {
      width:100%;
    }
    @media (min-width: $breakpoint-sm) {
      width:20%;
    }
    @media (min-width: $breakpoint-md) {
      width:15%;
    }
    .project-details-ingredients-container{
      flex-basis: 100%;
      @media (min-width: $breakpoint-xs) {
        margin-bottom: 15px;
      }
      @media (min-width: $breakpoint-sm) {
        margin-bottom: 15px;
      }
      @media (min-width: $breakpoint-md) {
        margin-bottom: 20px;
      }
      h3{
        margin: 0;
        margin-bottom: 10px;
        color: #FFF;
      text-align: justify;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      }
    }
    .project-details-role-container{
      flex-basis: 100%;
      @media (min-width: $breakpoint-xs) {
        margin-bottom: 15px;
      }
      @media (min-width: $breakpoint-sm) {
        margin-bottom: 25px;
      }
      @media (min-width: $breakpoint-md) {
        margin-bottom: 40px;
      }
      h3{
        margin: 0;
        margin-bottom: 10px;
        color: #FFF;
        text-align: justify;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
      }
      .project-details-role-text{
        margin: 0;
        margin-bottom: 10px;
        color: #FFF;
        text-align: justify;
        font-family: Inter;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
      }
    }
    .project-details-button{
      flex-basis: 100%;
      margin-bottom: 10px;
      a{
        border: none;
        box-shadow: none;
        width: 100%;
        height: 40px;
        border-radius: 17px;
        margin-right: 40px;
        background-color: #D3C4F2;
        border: none;
        color: #23468C;
        text-align: center;
        font-family: Inter;
        
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;

        @media (min-width: $breakpoint-xs) {
          font-size: 16px;
        }
        @media (min-width: $breakpoint-sm) {
          font-size: 13px;
        }
        @media (min-width: $breakpoint-md) {
          font-size: 14px;
        }
        @media (min-width: $breakpoint-lg) {
          font-size: 16px;
        }

        &:hover {
          background-color: #142b5a;
          color: #fff;
        }
      }
    }
  }
}

div.category-chip {
  margin-right: 5px; // Spacing between chips
  margin-bottom: 5px; // Spacing below each chip, if they wrap to a new line
  background-color: #6C69BF;
  color:#fff;
  // Add more styling as needed
}